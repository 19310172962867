<template>
  <div class="custom-form-container">
    <form-panel ref="formPanel"
                v-bind="submitConfig"
                :form="form"
                :submitBefore="submitBefore"
                :submitSuccess="onSubmitSuccess"
                @update="update">
      <col2-detail>
        <col2-block title="客户信息"
                    v-if="!isView">
          <el-form-item label="意向面积">
            <v-select v-model="form.intentionArea"
                      :options="optionsMap.ENTERPRISE_INTENTION_AREA"
                      :width="width" />
          </el-form-item>
          <el-form-item label="意向楼层">
            <v-input v-model="form.intentionFloor"
                     placeholder="请输入意向楼层"
                     :width="width" />
          </el-form-item>
          <el-form-item label="资金预算"
                        prop="budget"
                        :rules="[{ validator: numberValidatorGenerate(10), trigger: 'blur' }]">
            <v-input-number v-model="form.budget"
                            :max="9999999999"
                            placeholder="请输入资金预算"
                            :width="width" />万元
          </el-form-item>
          <el-form-item label="风险预估">
            <v-select v-model="form.riskAssessment"
                      :options="optionsMap.ENTERPRISE_RISK_ASSESSMENT"
                      :width="width" />
          </el-form-item>
          <el-form-item label="其他补充">
            <v-input v-model="form.otherSupplement"
                     placeholder="请输入其他补充"
                     :width="width" />
          </el-form-item>
        </col2-block>
        <col2-block title="客户信息"
                    v-else>
          <col2-item label="意向面积"
                     :text="form.intentionAreaStr" />
          <col2-item label="意向楼层"
                     :text="form.intentionFloor" />
          <col2-item label="资金预算"
                     :text="form.budget ? `${form.budget}万元` : form.budget" />
          <col2-item label="风险预估"
                     :text="form.riskAssessmentStr" />
          <col2-item label="其他补充"
                     :text="form.otherSupplement" />
        </col2-block>
        <col2-block title="跟踪记录">
          <v-button text="新增"
                    style="margin-bottom: 10px"
                    v-if="!isView"
                    @click="add" />
          <table-panel :headers="headers"
                       :tableData="form.trackList"
                       :hasOperateColumn="!isView">
            <template #operateSlot="scope">
              <div class="operate">
                <v-button type="text"
                          @click="edit(scope.row)">编辑</v-button>
                <v-button type="text"
                          @click="remove(scope.row)">删除</v-button>
              </div>
            </template>
          </table-panel>
        </col2-block>
      </col2-detail>
    </form-panel>
    <el-dialog title="添加跟踪记录"
               width="700px"
               :visible.sync="modalVisible">
      <el-form ref="recordForm"
               :model="record"
               label-width="100px">
        <el-form-item label="跟踪时间"
                      :rules="[{required: true, message: '请选择跟踪时间', trigger: 'change'}]"
                      prop="trackTime">
          <v-datepicker type="datetime"
                        :width="width"
                        :append-to-body="false"
                        v-model="record.trackTime" />
        </el-form-item>
        <el-form-item label="记录人员">
          <div>{{ record.name }}</div>
        </el-form-item>
        <el-form-item label="跟踪方式"
                      :rules="[{required: true, message: '请选择跟踪方式', trigger: 'blur'}]"
                      prop="trackWay">
          <v-input v-model="record.trackWay"
                   :width="width" />
        </el-form-item>
        <el-form-item label="招商进度"
                      :rules="[{required: true, message: '请选择招商进度', trigger: 'change'}]"
                      prop="investmentProgress">
          <v-select v-model="record.investmentProgress"
                    :options="optionsMap.ENTERPRISE_INVESTMENT_PROGRESS"
                    :width="width" />
        </el-form-item>
        <el-form-item label="内容"
                      :rules="[{required: true, message: '请填写内容', trigger: 'change'}]"
                      prop="trackContent">
          <v-textarea v-model="record.trackContent"
                      :maxlength="1000" />
        </el-form-item>
      </el-form>
      <template #footer>
        <v-button type="deafault"
                  style="margin-right:10px"
                  @click="modalVisible = false"
                  text="取消" />
        <v-button @click="onConfirm"
                  text="确认" />
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { queryDictURL, customEditURL, costomQueryURL } from './api'
import moment from 'moment'
import { setStatusOps } from './map'
import { Col2Detail, Col2Block, TablePanel, Col2Item } from 'components/bussiness'
let KEY = 1
export default {
  name: 'CustomForm',
  components: {
    Col2Detail,
    Col2Block,
    TablePanel,
    Col2Item
  },
  computed: {
    userName () {
      return this.$store.state.userInfo.userName
    },
    isView () {
      return this.$route.query.id && this.$route.query.model === 'view'
    }
  },
  data () {
    return {
      width: 200,
      form: {
        id: undefined,
        intentionArea: undefined,
        intentionFloor: undefined,
        budget: undefined,
        riskAssessment: undefined,
        otherSupplement: undefined,
        trackList: [],
        intentionAreaStr: undefined,
        riskAssessmentStr: undefined
      },
      statusOps: setStatusOps(),
      submitConfig: {
        queryUrl: costomQueryURL,
        submitUrl: ''
      },
      headers: [
        {
          prop: 'trackTime',
          label: '跟踪时间',
          formatter: row => moment(row.trackTime).format('YYYY-MM-DD HH:mm')
        },
        {
          prop: 'name',
          label: '记录人员'
        },
        {
          prop: 'trackWay',
          label: '跟踪方式'
        },
        {
          prop: 'investmentProgress',
          label: '招商进度',
          formatter: row => this.optMap.ENTERPRISE_INVESTMENT_PROGRESS[row.investmentProgress]
        },
        {
          prop: 'trackContent',
          label: '内容'
        }
      ],
      modalVisible: false,
      record: {
        trackTime: '',
        trackWay: '',
        investmentProgress: undefined,
        trackContent: '',
        name: ''
      },
      optionsMap: {
        ENTERPRISE_INTENTION_AREA: [],
        ENTERPRISE_RISK_ASSESSMENT: [],
        ENTERPRISE_INVESTMENT_PROGRESS: []
      },
      optMap: {
        ENTERPRISE_INTENTION_AREA: {},
        ENTERPRISE_RISK_ASSESSMENT: {},
        ENTERPRISE_INVESTMENT_PROGRESS: {}
      }
    }
  },
  mounted () {
    this.fetchOptions()
    const { id } = this.$route.query
    if (!this.isView) {
      this.$setBreadcrumb('编辑')
      this.submitConfig.submitUrl = customEditURL
      this.submitConfig.submitMethod = 'PUT'
    } else {
      this.submitConfig.submitUrl = ''
    }

    this.$refs.formPanel.getData({ enterpriseId: id })
    // TODO: 隐藏测试自动生成的测试数据
    // this.form.intentionArea = Math.ceil(Math.random() * 6).toString()
    // this.form.intentionFloor = Math.ceil(Math.random() * 20) + '楼'
    // this.form.budget = Math.ceil(Math.random() * 2000)
    // this.form.riskAssessment = Math.ceil(Math.random() * 4).toString()
    // this.form.otherSupplement = '测试数据'
  },
  methods: {
    submitBefore (data) {
      data.enterpriseId = this.$route.query.id
      return true
    },
    update (data) {
      if (data) {
        Object.keys(this.form).forEach(key => {
          this.form[key] = data[key]
        })
        let keys = ['intentionArea', 'riskAssessment']
        keys.forEach(key => { if (this.form[key] === 0) { this.form[key] = undefined } })
        this.form.trackList = this.form.trackList.map(item => ({ ...item, key: KEY++, name: item.inuserName }))
      }
    },
    add () {
      this.modalVisible = true
      this.$nextTick(() => {
        this.$refs.recordForm.resetFields()
        this.record = {
          trackTime: '',
          trackWay: '',
          investmentProgress: undefined,
          trackContent: '',
          name: ''
        }
        this.record.name = this.userName // 默认当前用户
        this.record.trackTime = moment().format('YYYY-MM-DD HH:mm')
        this.record.investmentProgress = undefined
        let maxTime = 0
        this.form.trackList.forEach(item => {
          if (moment(item.trackTime).valueOf() > maxTime) {
            maxTime = moment(item.trackTime).valueOf()
            this.record.investmentProgress = item.investmentProgress
          }
        })
      })
    },
    async onConfirm () {
      try {
        await this.$refs.recordForm.validate()
        this.modalVisible = false
        if (!this.record.key) {
          this.form.trackList.push({ ...this.record, key: KEY++ })
        } else {
          this.form.trackList = this.form.trackList.map(item => item.key === this.record.key ? this.record : item)
        }
      } catch (error) {

      }
    },
    remove (row) {
      this.form.trackList = this.form.trackList.filter(item => item.key !== row.key)
    },
    edit (row) {
      this.record = { ...row }
      this.modalVisible = true
    },
    // 查询下拉选项
    async fetchOptions () {
      let { data } = await this.$axios.get(queryDictURL, {
        params: {
          typeValueList: [
            'ENTERPRISE_INTENTION_AREA',
            'ENTERPRISE_RISK_ASSESSMENT',
            'ENTERPRISE_INVESTMENT_PROGRESS'
          ].join(',')
        }
      })
      if (data) {
        Object.keys(data).forEach(key => {
          this.optionsMap[key] = data[key].map(({ id, name }) => ({ text: name, value: id }))
          data[key].forEach(({ id, name }) => {
            this.optMap[key][id] = name
          })
        })
      }
    },
    onSubmitSuccess () {
      this.$emit('contact', {
        method: 'setActiveLabel',
        data: {
          disabled: false,
          activeLabel: '财务信息'
        }
      })
    },
    numberValidatorGenerate (num) {
      return function (rule, value, callback) {
        console.log(String(value).length)
        if ((value || value === 0) && String(value).length > num) {
          callback(new Error(`数字最大长度限制为${num}位`))
        } else {
          callback()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
.el-tooltip__popper {
  max-width: 500px;
}
</style>
