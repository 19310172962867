var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "custom-form-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.onSubmitSuccess,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              !_vm.isView
                ? _c(
                    "col2-block",
                    { attrs: { title: "客户信息" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "意向面积" } },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.optionsMap.ENTERPRISE_INTENTION_AREA,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.intentionArea,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "intentionArea", $$v)
                              },
                              expression: "form.intentionArea",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "意向楼层" } },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入意向楼层",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.intentionFloor,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "intentionFloor", $$v)
                              },
                              expression: "form.intentionFloor",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "资金预算",
                            prop: "budget",
                            rules: [
                              {
                                validator: _vm.numberValidatorGenerate(10),
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input-number", {
                            attrs: {
                              max: 9999999999,
                              placeholder: "请输入资金预算",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.budget,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "budget", $$v)
                              },
                              expression: "form.budget",
                            },
                          }),
                          _vm._v("万元\n        "),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "风险预估" } },
                        [
                          _c("v-select", {
                            attrs: {
                              options:
                                _vm.optionsMap.ENTERPRISE_RISK_ASSESSMENT,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.riskAssessment,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "riskAssessment", $$v)
                              },
                              expression: "form.riskAssessment",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "其他补充" } },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入其他补充",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.otherSupplement,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "otherSupplement", $$v)
                              },
                              expression: "form.otherSupplement",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "col2-block",
                    { attrs: { title: "客户信息" } },
                    [
                      _c("col2-item", {
                        attrs: {
                          label: "意向面积",
                          text: _vm.form.intentionAreaStr,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "意向楼层",
                          text: _vm.form.intentionFloor,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "资金预算",
                          text: _vm.form.budget
                            ? `${_vm.form.budget}万元`
                            : _vm.form.budget,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "风险预估",
                          text: _vm.form.riskAssessmentStr,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "其他补充",
                          text: _vm.form.otherSupplement,
                        },
                      }),
                    ],
                    1
                  ),
              _c(
                "col2-block",
                { attrs: { title: "跟踪记录" } },
                [
                  !_vm.isView
                    ? _c("v-button", {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { text: "新增" },
                        on: { click: _vm.add },
                      })
                    : _vm._e(),
                  _c("table-panel", {
                    attrs: {
                      headers: _vm.headers,
                      tableData: _vm.form.trackList,
                      hasOperateColumn: !_vm.isView,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "operateSlot",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "operate" },
                              [
                                _c(
                                  "v-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.edit(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c(
                                  "v-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.remove(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加跟踪记录",
            width: "700px",
            visible: _vm.modalVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.modalVisible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c("v-button", {
                    staticStyle: { "margin-right": "10px" },
                    attrs: { type: "deafault", text: "取消" },
                    on: {
                      click: function ($event) {
                        _vm.modalVisible = false
                      },
                    },
                  }),
                  _c("v-button", {
                    attrs: { text: "确认" },
                    on: { click: _vm.onConfirm },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-form",
            {
              ref: "recordForm",
              attrs: { model: _vm.record, "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "跟踪时间",
                    rules: [
                      {
                        required: true,
                        message: "请选择跟踪时间",
                        trigger: "change",
                      },
                    ],
                    prop: "trackTime",
                  },
                },
                [
                  _c("v-datepicker", {
                    attrs: {
                      type: "datetime",
                      width: _vm.width,
                      "append-to-body": false,
                    },
                    model: {
                      value: _vm.record.trackTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.record, "trackTime", $$v)
                      },
                      expression: "record.trackTime",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "记录人员" } }, [
                _c("div", [_vm._v(_vm._s(_vm.record.name))]),
              ]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "跟踪方式",
                    rules: [
                      {
                        required: true,
                        message: "请选择跟踪方式",
                        trigger: "blur",
                      },
                    ],
                    prop: "trackWay",
                  },
                },
                [
                  _c("v-input", {
                    attrs: { width: _vm.width },
                    model: {
                      value: _vm.record.trackWay,
                      callback: function ($$v) {
                        _vm.$set(_vm.record, "trackWay", $$v)
                      },
                      expression: "record.trackWay",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "招商进度",
                    rules: [
                      {
                        required: true,
                        message: "请选择招商进度",
                        trigger: "change",
                      },
                    ],
                    prop: "investmentProgress",
                  },
                },
                [
                  _c("v-select", {
                    attrs: {
                      options: _vm.optionsMap.ENTERPRISE_INVESTMENT_PROGRESS,
                      width: _vm.width,
                    },
                    model: {
                      value: _vm.record.investmentProgress,
                      callback: function ($$v) {
                        _vm.$set(_vm.record, "investmentProgress", $$v)
                      },
                      expression: "record.investmentProgress",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "内容",
                    rules: [
                      {
                        required: true,
                        message: "请填写内容",
                        trigger: "change",
                      },
                    ],
                    prop: "trackContent",
                  },
                },
                [
                  _c("v-textarea", {
                    attrs: { maxlength: 1000 },
                    model: {
                      value: _vm.record.trackContent,
                      callback: function ($$v) {
                        _vm.$set(_vm.record, "trackContent", $$v)
                      },
                      expression: "record.trackContent",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }