<template>
  <div class="EnterpriseRegisterForm">
    <form-panel ref="formPanel"
                v-bind="submitConfig"
                :form="form"
                :submitBefore="submitBefore"
                :submitSuccess="onSubmitSuccess"
                @update="update">
      <col2-detail v-if="!isView">
        <col2-block title="企业简介">
          <el-form-item label="企业名称"
                        :rules="[{ required: true, message: '请输入企业名称', trigger: 'blur' }]"
                        prop="enterpriseName">
            <v-input v-model="form.enterpriseName"
                     placeholder="请输入名称"
                     :width="width" />
          </el-form-item>
          <el-form-item label="行业类别"
                        :rules="[{ required: true, message: '请选择行业类别', trigger: 'change' }]"
                        prop="industryType">
            <v-select v-model="form.industryType"
                      :options="optionsMap.ENTERPRISE_INDUSTRY_TYPE"
                      :width="width" />
          </el-form-item>
          <el-form-item label="企业人数"
                        :rules="[{ required: true, message: '请选择企业人数', trigger: 'change' }]"
                        prop="enterpriseNum">
            <v-select v-model="form.enterpriseNum"
                      :options="optionsMap.ENTERPRISE_NUM"
                      :width="width" />
          </el-form-item>
          <el-form-item>
            <v-button :icon="showOptional ? 'el-icon-top' : 'el-icon-bottom'" text="选填信息" @click="showOptional = !showOptional"></v-button>
          </el-form-item>
          <div v-if="showOptional">
            <el-form-item label="战略性新兴产业">
              <v-select v-model="form.enterpriseNewIndustry"
                        :options="optionsMap.ENTERPRISE_NEW_INDUSTRY"
                        :width="width" />
            </el-form-item>
            <el-form-item label="纳税人类型">
              <v-select v-model="form.taxpayerType"
                        :options="optionsMap.ENTERPRISE_TAXPAYER_TYPE"
                        :width="width" />
            </el-form-item>
            <el-form-item label="企业评级">
              <v-select v-model="form.enterpriseLevel"
                        :options="optionsMap.ENTERPRISE_LEVEL"
                        :width="width" />
            </el-form-item>
            <el-form-item label="主要产品/服务">
              <v-textarea v-model="form.mainProducts"></v-textarea>
            </el-form-item>
          </div>
        </col2-block>
        <col2-block title="工商信息" v-if="showOptional">
          <el-form-item label="法人">
            <v-input :width="width"
                     :maxlength="8"
                     v-model="form.legalPerson" />
          </el-form-item>
          <el-form-item label="统一社会信用代码"
                        prop="creditCode"
                        :rules="[{ validator: creditCodeValidate, trigger: 'blur' }]">
            <v-input :width="width"
                     :maxlength="18"
                     v-model="form.creditCode" />
          </el-form-item>
          <el-form-item label="企业类型">
            <v-select v-model="form.enterpriseType"
                      :options="optionsMap.ENTERPRISE_TYPE"
                      :width="width" />
          </el-form-item>
          <el-form-item label="注册时间">
            <v-datepicker v-model="form.registerTime"
                          :width="width"
                          type="date" />
          </el-form-item>
          <el-form-item label="注册资本"
                        prop="registerCapital"
                        :rules="[{ validator: numberValidatorGenerate(9), trigger: 'blur' }]">
            <v-input-number :width="width"
                            :max="999999999"
                            v-model="form.registerCapital" />万元
          </el-form-item>
          <el-form-item label="企业规模">
            <v-select v-model="form.enterpriseScale"
                      :options="optionsMap.ENTERPRISE_SCALE"
                      :width="width" />
          </el-form-item>
          <el-form-item label="参保人数"
                        prop="insuredNum"
                        :rules="[{ validator: numberValidatorGenerate(10), trigger: 'blur' }]">
            <v-input-number :width="width"
                            :max="9999999999"
                            v-model="form.insuredNum" />
          </el-form-item>
          <el-form-item label="企业地址">
            <v-input :width="width"
                     v-model="form.enterpriseAddress" />
          </el-form-item>
          <el-form-item label="经营范围">
            <v-textarea v-model="form.businessScope"
                        :maxlength="1000"></v-textarea>
          </el-form-item>
        </col2-block>
        <col2-block title="跟踪信息">
          <el-form-item label="企业联系人"
                        :rules="[{ required: true, message: '请输入企业联系人', trigger: 'blur' }]"
                        prop="enterpriseContact">
            <v-input v-model="form.enterpriseContact"
                     placeholder="请输入企业联系人"
                     :maxlength="8"
                     :width="width" />
          </el-form-item>
          <el-form-item label="联系人职务">
            <v-input :width="width"
                     v-model="form.enterpriseContactDuty" />
          </el-form-item>
          <el-form-item label="联系人电话"
                        :rules="[{ required: true, message: '请输入联系人电话', trigger: 'blur' },
                        { validator: validatePhone, trigger: 'blur' }]"
                        prop="enterpriseContactPhone">
            <v-input v-model="form.enterpriseContactPhone"
                     :maxlength="20"
                     placeholder="请输入联系人电话"
                     :width="width" />
          </el-form-item>
          <el-form-item label="登记项目"
                        :rules="[{ required: true, message: '请选择登记项目', trigger: 'blur' }]"
                        prop="communityId">
            <v-select2 :width="width"
                       placeholder="查询项目"
                       v-model="form.communityId"
                       v-bind="communityParams" />
          </el-form-item>
          <el-form-item label="跟踪人员"
                        :rules="[{ required: true, message: '请选择跟踪人员', trigger: 'blur' }]"
                        prop="trackUserId">
            <v-select2 :width="width"
                       placeholder="查询人员"
                       v-model="form.trackUserId"
                       v-bind="userParams"
                       :subjoin="{communityId: form.communityId}" />
          </el-form-item>
          <template v-if="!isEdit">
            <el-form-item label="企业招商状态">
              <div>意向企业</div>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item label="企业招商状态">
              <div>{{statusMap[form.status]}}</div>
            </el-form-item>
            <template v-if="String(form.status) === '1'">
              <el-form-item label="合同名称">
                <div>{{form.contractName}}</div>
              </el-form-item>
              <el-form-item label="合同编号">
                <div>{{form.contractId}}</div>
              </el-form-item>
              <el-form-item label="合同状态">
                <div>{{form.contractStatus}}</div>
              </el-form-item>
            </template>
            <template v-if="String(form.status) === '2'">
              <el-form-item label="流失原因">
                <div>{{form.enterpriseLossReasonStr}}</div>
              </el-form-item>
              <el-form-item label="备注">
                <div>{{form.remark}}</div>
              </el-form-item>
            </template>
          </template>
        </col2-block>
      </col2-detail>
      <!-- 详情 -->
      <col2-detail v-if="isView">
        <col2-block title="企业简介">
          <col2-item label="企业名称">
            {{form.enterpriseName}}
          </col2-item>
          <col2-item label="行业类别">
            {{optMap.ENTERPRISE_INDUSTRY_TYPE[form.industryType]}}
          </col2-item>
          <col2-item label="企业人数">
            {{optMap.ENTERPRISE_NUM[form.enterpriseNum]}}
          </col2-item>
          <col2-item label="战略性新兴产业">
            {{optMap.ENTERPRISE_NEW_INDUSTRY[form.enterpriseNewIndustry]}}
          </col2-item>
          <col2-item label="纳税人类型">
            {{optMap.ENTERPRISE_TAXPAYER_TYPE[form.taxpayerType]}}
          </col2-item>
          <col2-item label="企业评级">
            {{optMap.ENTERPRISE_LEVEL[form.enterpriseLevel]}}
          </col2-item>
          <col2-item label="主要产品/服务">
            {{form.mainProducts}}
          </col2-item>
        </col2-block>
        <col2-block title="工商信息">
          <col2-item label="法人"
                     :text="form.legalPerson" />
          <col2-item label="统一社会信用代码"
                     :text="form.creditCode" />
          <col2-item label="注册时间"
                     :text="form.registerTime" />
          <col2-item label="注册资本"
                     :text="form.registerCapital ? `${form.registerCapital}万元` : '未完善'" />
          <col2-item label="企业规模"
                     :text="optMap.ENTERPRISE_SCALE[form.enterpriseScale]" />
          <col2-item label="参保人数"
                     :text="form.insuredNum" />
          <col2-item label="企业地址"
                     :text="form.enterpriseAddress" />
          <col2-item label="经营范围"
                     :text="form.businessScope" />
        </col2-block>
        <col2-block title="跟踪信息">
          <col2-item label="企业联系人"
                     :text="form.enterpriseContact" />
          <col2-item label="联系人职务"
                     :text="form.enterpriseContactDuty" />
          <col2-item label="联系人电话"
                     :text="form.enterpriseContactPhone" />
          <col2-item label="登记项目"
                     :text="form.communityName" />
          <col2-item label="跟踪人员"
                     :text="form.trackUserName" />

        </col2-block>
        <col2-item label="企业招商状态"
                   :text="statusMap[form.status]" />
        <template v-if="String(form.status) === '1'">
          <col2-item label="合同名称"
                     :text="form.contractName" />
          <col2-item label="合同编号"
                     :text="form.contractId" />
          <col2-item label="合同状态"
                     :text="form.contractStatus" />
        </template>
        <template v-if="String(form.status) === '2'">
          <col2-item label="流失原因"
                     :text="form.enterpriseLossReasonStr" />
          <col2-item label="备注"
                     :text="form.remark" />
        </template>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { createURL, editURL, queryURL, queryCommunityUserURL, queryDictURL } from './api'
import { setStatusOps, statusMap } from './map'
import { Col2Detail, Col2Block, Col2Item } from 'components/bussiness'
import { communityParams } from 'common/select2Params'
import { mobileRegular, telephoneRegular } from 'common/regular'

export default {
  name: 'EnterpriseRegisterForm',
  components: {
    Col2Detail,
    Col2Block,
    Col2Item
  },
  data () {
    return {
      showOptional: false,
      width: 200,
      statusOps: setStatusOps(),
      form: {
        id: undefined,
        enterpriseName: '',
        industryType: undefined,
        enterpriseNum: undefined,
        enterpriseNewIndustry: undefined,
        taxpayerType: undefined,
        enterpriseLevel: undefined,
        mainProducts: '',
        legalPerson: '',
        creditCode: undefined,
        enterpriseType: undefined,
        registerTime: undefined,
        registerCapital: undefined,
        enterpriseScale: undefined,
        insuredNum: undefined,
        enterpriseAddress: undefined,
        businessScope: '',
        enterpriseContact: undefined,
        enterpriseContactDuty: undefined,
        enterpriseContactPhone: undefined,
        communityId: undefined,
        trackUserId: undefined,
        status: 0,
        communityName: undefined,
        contractId: undefined,
        trackUserName: undefined,
        remark: undefined,
        enterpriseLossReasonStr: undefined,
        contractStatus: undefined,
        contractName: undefined
      },
      submitConfig: {
        queryUrl: queryURL,
        submitUrl: '' // TODO 填写url
      },
      communityParams,
      userParams: {
        searchUrl: queryCommunityUserURL,
        request: {
          text: 'username',
          value: 'userId'
        },
        handleData: data => data.map(item => {
          let arr = []
          if (item.username) {
            arr.push(item.username)
          }
          if (item.mobileNum) {
            arr.push(item.mobileNum)
          }
          return {
            id: item.id,
            name: arr.join('-')
          }
        })
      },
      optionsMap: {
        ENTERPRISE_INDUSTRY_TYPE: [],
        ENTERPRISE_LEVEL: [],
        ENTERPRISE_NEW_INDUSTRY: [],
        ENTERPRISE_NUM: [],
        ENTERPRISE_SCALE: [],
        ENTERPRISE_TAXPAYER_TYPE: [],
        ENTERPRISE_TYPE: []
      },
      statusMap,
      // 选项枚举,回显对应的文本
      optMap: {
        ENTERPRISE_INDUSTRY_TYPE: {},
        ENTERPRISE_LEVEL: {},
        ENTERPRISE_NEW_INDUSTRY: {},
        ENTERPRISE_NUM: {},
        ENTERPRISE_SCALE: {},
        ENTERPRISE_TAXPAYER_TYPE: {},
        ENTERPRISE_TYPE: {}
      }
    }
  },
  mounted () {
    // TODO 清除自动赋值代码
    // this.form.enterpriseName = '测试企业' + new Date().valueOf()
    // this.form.industryType = Math.ceil(Math.random() * 20).toString()
    // this.form.enterpriseNum = Math.ceil(Math.random() * 7).toString()
    // this.form.enterpriseNewIndustry = Math.ceil(Math.random() * 10).toString()
    // this.form.taxpayerType = Math.ceil(Math.random() * 2).toString()
    // this.form.enterpriseLevel = Math.ceil(Math.random() * 10).toString()
    // this.form.mainProducts = '大豆销售'
    // this.form.enterpriseContact = '胡蒙川'
    // this.form.legalPerson = '王铁锤'
    // this.form.creditCode = 'F133DFF2345566G'
    // this.form.enterpriseType = Math.ceil(Math.random() * 10).toString()
    // this.form.enterpriseContactPhone = '15202416996'
    // this.form.registerTime = '2012-12-12'
    // this.form.registerCapital = 20
    // this.form.enterpriseScale = Math.ceil(Math.random() * 4).toString()
    // this.form.insuredNum = '200'
    // this.form.enterpriseAddress = '华星时代广场A座1505'
    // this.form.businessScope = '农贸产品'
    // this.form.enterpriseContactDuty = '总经理'
    // this.form.communityId = '4829905d-cb9e-11e8-8a58-506b4b417204'
    // this.form.communityId = '46e0b08f-cd53-11e9-8a58-506b4b417204'
    // this.form.trackUserId = '0d6af691-5cd0-11e9-8a58-506b4b417204'

    const { id } = this.$route.query
    if (this.isEdit) {
      this.$setBreadcrumb('编辑')
      this.submitConfig.submitUrl = editURL
      this.submitConfig.submitMethod = 'PUT'
      this.$refs.formPanel.getData({ id })
    } else if (this.isView) {
      this.$setBreadcrumb('详情')
      this.submitConfig.submitUrl = ''
      this.$refs.formPanel.getData({ id })
    } else {
      this.$setBreadcrumb('新增')
      this.submitConfig.submitUrl = createURL
    }
    this.fetchOptions()
  },
  computed: {
    isEdit () {
      return this.$route.query.id && this.$route.query.model === 'edit'
    },
    isView () {
      return this.$route.query.id && this.$route.query.model === 'view'
    }
  },
  methods: {
    validatePhone (rule, value, callback) {
      let regExp = mobileRegular
      let connectionRegExp = telephoneRegular
      if (!regExp.test(value) && !connectionRegExp.test(value)) {
        callback(new Error('手机/电话号码格式不正确'))
      } else {
        callback()
      }
    },
    update (data) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
      let keys = ['enterpriseLevel', 'taxpayerType', 'enterpriseNewIndustry', 'enterpriseScale', 'enterpriseType']
      keys.forEach(key => {
        if (this.form[key] === 0) this.form[key] = undefined
      })
    },
    submitBefore (data) {
      return true
    },
    // 查询下拉选项
    async fetchOptions () {
      let { data } = await this.$axios.get(queryDictURL, {
        params: {
          typeValueList: [
            'ENTERPRISE_INDUSTRY_TYPE',
            'ENTERPRISE_NUM',
            'ENTERPRISE_NEW_INDUSTRY',
            'ENTERPRISE_TAXPAYER_TYPE',
            'ENTERPRISE_LEVEL',
            'ENTERPRISE_TYPE',
            'ENTERPRISE_SCALE'
          ].join(',')
        }
      })
      if (data) {
        Object.keys(data).forEach(key => {
          this.optionsMap[key] = data[key].map(({ id, name }) => ({ text: name, value: id }))
          data[key].forEach(({ id, name }) => {
            this.optMap[key][id] = name
          })
          if (['ENTERPRISE_NEW_INDUSTRY', 'ENTERPRISE_TAXPAYER_TYPE', 'ENTERPRISE_LEVEL', 'ENTERPRISE_SCALE', 'ENTERPRISE_TYPE'].includes(key)) {
            this.optionsMap[key].unshift({ text: '请选择', value: undefined })
          }
        })
      }
    },
    onSubmitSuccess (id) {
      this.$emit('contact', {
        method: 'setActiveLabel',
        data: {
          disabled: false,
          activeLabel: '客户记录'
        }
      })
      let query = {
        id,
        model: 'create'
      }
      if (this.isEdit) {
        query = {
          id: this.$route.query.id,
          model: 'edit'
        }
      }
      this.$router.replace({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          ...query
        }
      })
    },
    creditCodeValidate (rule, value, callback) {
      let reg = /^[A-Z0-9]{18}$/
      if (!reg.test(value) && value) {
        callback(new Error('请输入正确的信用代码(18位数字或大写英文字母)'))
      } else {
        callback()
      }
    },
    numberValidatorGenerate (num) {
      return function (rule, value, callback) {
        if ((value || value === 0) && value.toString().length > num) {
          callback(new Error(`数字最大长度限制为${num}位`))
        } else {
          callback()
        }
      }
    }
  }
}
</script>
