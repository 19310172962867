<template>
  <div class="financial-form-container">
    <form-panel ref="formPanel"
                v-bind="submitConfig"
                :form="form"
                :submitBefore="submitBefore"
                @update="update">
      <col2-detail :span="24">
        <col2-block title="营收情况">
          <v-button text="新增"
                    style="margin-bottom: 10px"
                    @click="revenueAdd"
                    v-if="!isView" />
          <table-panel :headers="revenueHeader"
                       :tableData="form.revenueList"
                       :hasOperateColumn="false">
            <template #operateSlot="scope">
              <div class="operate">
                <v-button type="text"
                          style="margin-right: 10px"
                          @click="revenueEdit(scope.row)">编辑</v-button>
                <v-button type="text"
                          @click="revenueRemove(scope.row)">删除</v-button>
              </div>
            </template>
          </table-panel>
        </col2-block>
        <col2-block title="融资情况">
          <v-button text="新增"
                    style="margin-bottom: 10px"
                    v-if="!form.financingList.length && !isView"
                    @click="financeAdd" />
          <table-panel :headers="financeHeader"
                       :tableData="form.financingList"
                       :hasOperateColumn="false">
            <template #operateSlot="scope">
              <div class="operate">
                <v-button type="text"
                          style="margin-right: 10px"
                          @click="financeEdit(scope.row)">编辑</v-button>
                <v-button type="text"
                          @click="financeRemove(scope.row)">删除</v-button>
              </div>
            </template>
          </table-panel>
        </col2-block>
        <col2-block title="贷款情况">
          <v-button text="新增"
                    style="margin-bottom: 10px"
                    @click="loanAdd"
                    v-if="!isView" />
          <table-panel :headers="loanHeader"
                       :tableData="form.loanList"
                       :hasOperateColumn="false">
            <template #operateSlot="scope">
              <div class="operate">
                <v-button type="text"
                          style="margin-right: 10px"
                          @click="loanEdit(scope.row)">编辑</v-button>
                <v-button type="text"
                          @click="loanRemove(scope.row)">删除</v-button>
              </div>
            </template>
          </table-panel>
        </col2-block>
      </col2-detail>
    </form-panel>
    <!-- 添加营收情况弹框 -->
    <el-dialog title="添加营收情况"
               width="600px"
               :visible.sync="revenueModalVisible">
      <el-form ref="revenueForm"
               :model="revenueForm"
               label-width="200px">
        <el-form-item label="年份"
                      :rules="{ required: true, message: '请选择年份', trigger: 'change'}"
                      prop="year">
          <v-datepicker type="year"
                        :width="width"
                        :append-to-body="false"
                        v-model="revenueForm.year" />
        </el-form-item>
        <el-form-item label="年度主营收入"
                      :rules="[{ required: true, message: '请填写年度主营收入', trigger: 'blur'}, { validator: numberValidatorGenerate(10), trigger: 'blur' }]"
                      prop="yearMainIncome">
          <v-input-number v-model="revenueForm.yearMainIncome"
                          :max="9999999999"
                          controls
                          controls-position="right"
                          :width="width" />万元
        </el-form-item>
        <el-form-item label="年度其他业务收入"
                      prop="yearOtherIncome"
                      :rules="[{ validator: numberValidatorGenerate(10), trigger: 'blur' }]">
          <v-input-number v-model="revenueForm.yearOtherIncome"
                          :max="9999999999"
                          controls
                          controls-position="right"
                          :width="width" />万元
        </el-form-item>
        <el-form-item label="年度净利润"
                      prop="yearNetProfit"
                      :rules="[{ validator: numberValidatorGenerate(10), trigger: 'blur' }]">
          <v-input-number v-model="revenueForm.yearNetProfit"
                          :max="9999999999"
                          controls
                          controls-position="right"
                          :width="width" />万元
        </el-form-item>
        <el-form-item label="年度税收"
                      prop="yearTaxRevenue"
                      :rules="[{ validator: numberValidatorGenerate(10), trigger: 'blur' }]">
          <v-input-number v-model="revenueForm.yearTaxRevenue"
                          :max="9999999999"
                          controls
                          controls-position="right"
                          :width="width" />万元
        </el-form-item>
        <el-form-item label="进出口交易额"
                      prop="importAndExport"
                      :rules="[{ validator: numberValidatorGenerate(10), trigger: 'blur' }]">
          <v-input-number v-model="revenueForm.importAndExport"
                          :max="9999999999"
                          controls
                          controls-position="right"
                          :width="width" />万元
        </el-form-item>
        <el-form-item label="投资支出"
                      prop="investmentExpenditure"
                      :rules="[{ validator: numberValidatorGenerate(10), trigger: 'blur' }]">
          <v-input-number v-model="revenueForm.investmentExpenditure"
                          :max="9999999999"
                          controls
                          controls-position="right"
                          :width="width" />万元
        </el-form-item>
        <el-form-item label="年度R&D投入"
                      prop="scientificExpenditure"
                      :rules="[{ validator: numberValidatorGenerate(10), trigger: 'blur' }]">
          <v-input-number v-model="revenueForm.scientificExpenditure"
                          :max="9999999999"
                          controls
                          controls-position="right"
                          :width="width" />万元
        </el-form-item>
        <el-form-item label="年度技术产品/服务收入"
                      prop="technologyProducts"
                      :rules="[{ validator: numberValidatorGenerate(10), trigger: 'blur' }]">
          <v-input-number v-model="revenueForm.technologyProducts"
                          :max="9999999999"
                          controls
                          controls-position="right"
                          :width="width" />万元
        </el-form-item>
      </el-form>
      <template #footer>
        <v-button type="deafault"
                  style="margin-right:10px"
                  @click="revenueModalVisible = false"
                  text="取消" />
        <v-button @click="onRevenueSave"
                  text="确认" />
      </template>
    </el-dialog>
    <!-- 添加融资情况弹框 -->
    <el-dialog title="添加融资情况"
               width="600px"
               :visible.sync="financeModalVisible">
      <el-form ref="financeForm"
               :model="financeForm"
               label-width="200px">
        <el-form-item label="已融资阶段"
                      :rules="{ required: true, message: '请选择融资阶段', trigger: 'change'}"
                      prop="financingStage">
          <v-select v-model="financeForm.financingStage"
                    :options="optionsMap.ENTERPRISE_FINANCING_STAGE"
                    :width="width" />
        </el-form-item>
        <el-form-item label="已获融资金额"
                      prop="alreadyFinancingAmount"
                      :rules="[{ validator: numberValidatorGenerate(10), trigger: 'blur' }]">
          <v-input-number v-model="financeForm.alreadyFinancingAmount"
                          :max="9999999999"
                          controls
                          controls-position="right"
                          :width="width" />万元
        </el-form-item>
        <el-form-item label="投资机构">
          <v-input v-model="financeForm.investmentInstitution"
                   :width="width" />
        </el-form-item>
        <el-form-item label="当前融资需求"
                      prop="currentFinancingNeeds"
                      :rules="[{ validator: numberValidatorGenerate(10), trigger: 'blur' }]">
          <v-input-number v-model="financeForm.currentFinancingNeeds"
                          :max="9999999999"
                          controls
                          controls-position="right"
                          :width="width" />万元
        </el-form-item>
        <el-form-item label="当前估值"
                      prop="currentValuation"
                      :rules="[{ validator: numberValidatorGenerate(10), trigger: 'blur' }]">
          <v-input-number v-model="financeForm.currentValuation"
                          :max="9999999999"
                          controls
                          controls-position="right"
                          :width="width" />万元
        </el-form-item>
      </el-form>
      <template #footer>
        <v-button type="deafault"
                  style="margin-right:10px"
                  @click="financeModalVisible = false"
                  text="取消" />
        <v-button @click="onFinanceSave"
                  text="确认" />
      </template>
    </el-dialog>
    <!-- 添加贷款情况 -->
    <el-dialog title="添加贷款情况"
               width="600px"
               :visible.sync="loanModalVisible">
      <el-form ref="loanForm"
               :model="loanForm"
               label-width="200px">
        <el-form-item label="已贷金额"
                      prop="loanAmount"
                      :rules="[{ required: true, message: '请输入已贷金额', trigger: 'blur' },{ validator: numberValidatorGenerate(10), trigger: 'blur' }]">
          <v-input-number v-model="loanForm.loanAmount"
                          :max="9999999999"
                          controls
                          controls-position="right"
                          :width="width" />万元
        </el-form-item>
        <el-form-item label="贷款银行">
          <v-input v-model="loanForm.loanBank"
                   :width="width" />
        </el-form-item>
        <el-form-item label="贷款类型">
          <v-select v-model="loanForm.loanType"
                    :options="optionsMap.ENTERPRISE_LOAN_TYPE"
                    :width="width" />
        </el-form-item>
        <el-form-item label="当前贷款需求"
                      prop="currentLoanNeeds"
                      :rules="[{ validator: numberValidatorGenerate(10), trigger: 'blur' }]">
          <v-input-number v-model="loanForm.currentLoanNeeds"
                          :max="9999999999"
                          controls
                          controls-position="right"
                          :width="width" />万元
        </el-form-item>
      </el-form>
      <template #footer>
        <v-button type="deafault"
                  style="margin-right:10px"
                  @click="loanModalVisible = false"
                  text="取消" />
        <v-button @click="onLoanSave"
                  text="确认" />
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { queryDictURL, financeUpdateURL, financeQueryURL } from './api'
import { setStatusOps } from './map'
import { Col2Detail, Col2Block, TablePanel } from 'components/bussiness'
let ID = {
  revenue: 1, // 营收
  finance: 1, // 融资
  loan: 1 // 贷款
}
export default {
  name: 'CustomForm',
  components: {
    Col2Detail,
    Col2Block,
    TablePanel
  },
  computed: {
    userName () {
      return this.$store.state.userInfo.userName
    },
    isView () {
      return this.$route.query.id && this.$route.query.model === 'view'
    }
  },
  data () {
    return {
      width: 200,
      form: {
        financingList: [],
        loanList: [],
        revenueList: [],
        enterpriseId: undefined
      },
      statusOps: setStatusOps(),
      submitConfig: {
        queryUrl: financeQueryURL,
        submitUrl: financeUpdateURL,
        submitMethod: 'PUT'
      },
      // 营收情况
      revenueHeader: [
        {
          prop: 'year',
          label: '年份'
        },
        {
          prop: 'yearMainIncome',
          label: '年度主营业务收入',
          align: 'right',
          formatter: row => row.yearMainIncome ? `${row.yearMainIncome}万元` : ''
        },
        {
          prop: 'yearOtherIncome',
          label: '年度其他业务收入',
          align: 'right',
          formatter: row => row.yearOtherIncome ? `${row.yearOtherIncome}万元` : ''
        },
        {
          prop: 'yearNetProfit',
          label: '年度净利润',
          align: 'right',
          formatter: row => row.yearNetProfit ? `${row.yearNetProfit}万元` : ''
        },
        {
          prop: 'yearTaxRevenue',
          label: '年度税收',
          align: 'right',
          formatter: row => row.yearTaxRevenue ? `${row.yearTaxRevenue}万元` : ''
        },
        {
          prop: 'importAndExport',
          label: '进出口交易额',
          align: 'right',
          formatter: row => row.importAndExport ? `${row.importAndExport}万元` : ''
        },
        {
          prop: 'investmentExpenditure',
          label: '投资支出',
          align: 'right',
          formatter: row => row.investmentExpenditure ? `${row.investmentExpenditure}万元` : ''
        },
        {
          prop: 'scientificExpenditure',
          label: '年度R&D投入',
          align: 'right',
          formatter: row => row.scientificExpenditure ? `${row.scientificExpenditure}万元` : ''
        },
        {
          prop: 'technologyProducts',
          label: '年度技术产品/服务收入',
          align: 'right',
          minWidth: 120,
          formatter: row => row.technologyProducts ? `${row.technologyProducts}万元` : ''
        }
      ],
      revenueModalVisible: false,
      revenueForm: {
        year: '',
        yearMainIncome: undefined,
        yearOtherIncome: undefined,
        yearNetProfit: undefined,
        yearTaxRevenue: undefined,
        importAndExport: undefined,
        investmentExpenditure: undefined,
        scientificExpenditure: undefined,
        technologyProducts: undefined
      },
      // 融资情况
      financeHeader: [
        {
          prop: 'financingStage',
          label: '已融资阶段',
          formatter: row => this.optMap.ENTERPRISE_FINANCING_STAGE[row.financingStage]
        },
        {
          prop: 'alreadyFinancingAmount',
          label: '已融资金额',
          align: 'right',
          formatter: row => row.alreadyFinancingAmount ? `${row.alreadyFinancingAmount}万元` : ''
        },
        {
          prop: 'investmentInstitution',
          label: '投资机构'
        },
        {
          prop: 'currentFinancingNeeds',
          label: '当前融资需求',
          align: 'right',
          formatter: row => row.currentFinancingNeeds ? `${row.currentFinancingNeeds}万元` : ''
        },
        {
          prop: 'currentValuation',
          label: '当前估值',
          align: 'right',
          formatter: row => row.currentValuation ? `${row.currentValuation}万元` : ''
        }
      ],
      financeModalVisible: false,
      financeForm: {
        financingStage: undefined,
        alreadyFinancingAmount: undefined,
        investmentInstitution: undefined,
        currentFinancingNeeds: undefined,
        currentValuation: undefined
      },
      // 贷款情况
      loanHeader: [
        {
          prop: 'loanAmount',
          label: '已贷金额',
          align: 'right',
          formatter: row => row.loanAmount ? `${row.loanAmount}万元` : ''
        },
        {
          prop: 'loanBank',
          label: '贷款银行'
        },
        {
          prop: 'loanType',
          label: '贷款类型',
          formatter: row => this.optMap.ENTERPRISE_LOAN_TYPE[row.loanType]
        },
        {
          prop: 'currentLoanNeeds',
          label: '当前贷款需求',
          align: 'right',
          formatter: row => row.currentLoanNeeds ? `${row.currentLoanNeeds}万元` : ''
        }
      ],
      loanModalVisible: false,
      loanForm: {
        loanAmount: undefined,
        loanBank: undefined,
        loanType: undefined,
        currentLoanNeeds: undefined
      },
      optionsMap: {
        ENTERPRISE_LOAN_TYPE: [],
        ENTERPRISE_FINANCING_STAGE: []
      },
      optMap: {
        ENTERPRISE_LOAN_TYPE: {},
        ENTERPRISE_FINANCING_STAGE: {}
      }
    }
  },
  mounted () {
    this.fetchOptions()
    let { id } = this.$route.query
    this.$refs.formPanel.getData({ enterpriseId: id })
    if (this.isView) {
      this.submitConfig.submitUrl = ''
    }
    // TODO:  待测除测试代码
    // this.form.revenueList = [
    //   {
    //     key: 1,
    //     year: '2010',
    //     yearMainIncome: 10,
    //     yearOtherIncome: 20,
    //     yearNetProfit: 10,
    //     yearTaxRevenue: 10,
    //     importAndExport: 10,
    //     investmentExpenditure: 10,
    //     scientificExpenditure: 10,
    //     technologyProducts: 10
    //   }
    // ]
    // this.form.financingList = [
    //   {
    //     key: 1,
    //     financingStage: '1',
    //     alreadyFinancingAmount: 10,
    //     investmentInstitution: '测试投资机构',
    //     currentFinancingNeeds: 10,
    //     currentValuation: 10
    //   }
    // ]
    // this.form.loanList = [
    //   {
    //     loanAmount: 10,
    //     loanBank: '光大银行',
    //     loanType: '1',
    //     currentLoanNeeds: 10
    //   }
    // ]
  },
  methods: {
    submitBefore (data) {
      return true
    },
    update (data) {
      this.form.financingList = data.financingVList.map(item => ({ ...item, key: ID.finance++ }))
      this.form.revenueList = data.revenueVList.map(item => ({ ...item, key: ID.revenue++ }))
      this.form.loanList = data.loanVList.map(item => ({ ...item, key: ID.loan++, loanType: item.loanType === 0 ? undefined : item.loanType }))
      this.form.enterpriseId = this.$route.query.id
    },
    revenueEdit (row) {
      this.revenueForm = { ...row }
      this.revenueModalVisible = true
    },
    revenueRemove (row) {
      this.form.revenueList = this.form.revenueList.filter(item => item.key !== row.key)
    },
    revenueAdd () {
      this.revenueModalVisible = true
      this.$nextTick(() => {
        this.$refs.revenueForm.resetFields()
        delete this.revenueForm.key
        this.revenueForm = {
          year: '',
          yearMainIncome: undefined,
          yearOtherIncome: undefined,
          yearNetProfit: undefined,
          yearTaxRevenue: undefined,
          importAndExport: undefined,
          investmentExpenditure: undefined,
          scientificExpenditure: undefined,
          technologyProducts: undefined
        }
      })
    },
    async onRevenueSave () {
      try {
        await this.$refs.revenueForm.validate()
        if (!this.revenueForm.key) {
          if (this.form.revenueList.find(item => item.year === this.revenueForm.year)) {
            this.$message('已填加过该年份的数据，请进行编辑操作')
            return
          }
          this.form.revenueList.push({ ...this.revenueForm, key: ID.revenue++ })
        } else {
          this.form.revenueList = this.form.revenueList.map(item => item.key === this.revenueForm.key ? this.revenueForm : item)
        }
        this.revenueModalVisible = false
      } catch (error) {

      }
    },
    financeEdit (row) {
      this.financeForm = { ...row }
      this.financeModalVisible = true
    },
    financeRemove (row) {
      this.form.financingList = this.form.financingList.filter(item => item.key !== row.key)
    },
    financeAdd () {
      this.financeModalVisible = true
      this.$nextTick(() => {
        this.$refs.financeForm.resetFields()
        delete this.financeForm.key
        this.financeForm = {
          financingStage: undefined,
          alreadyFinancingAmount: undefined,
          investmentInstitution: undefined,
          currentFinancingNeeds: undefined,
          currentValuation: undefined
        }
      })
    },
    async onFinanceSave () {
      try {
        await this.$refs.financeForm.validate()
        if (!this.financeForm.key) {
          if (this.form.financingList.find(item => item.financingStage === this.financeForm.financingStage)) {
            this.$message('已填加过该融资阶段的数据，请进行编辑操作')
            return
          }
          this.form.financingList.push({ ...this.financeForm, key: ID.finance++ })
        } else {
          this.form.financingList = this.form.financingList.map(item => item.key === this.financeForm.key ? this.financeForm : item)
        }
        this.financeModalVisible = false
      } catch (error) {

      }
    },
    loanEdit (row) {
      this.loanForm = { ...row }
      this.loanModalVisible = true
    },
    loanRemove (row) {
      this.form.loanList = this.form.loanList.filter(item => item.key !== row.key)
    },
    loanAdd () {
      this.loanModalVisible = true
      this.$nextTick(() => {
        this.$refs.loanForm.resetFields()
        delete this.loanForm.key
        this.loanForm = {
          loanAmount: undefined,
          loanBank: undefined,
          loanType: undefined,
          currentLoanNeeds: undefined
        }
      })
    },
    async onLoanSave () {
      try {
        await this.$refs.loanForm.validate()
        if (!this.loanForm.key) {
          this.form.loanList.push({ ...this.loanForm, key: ID.loan++ })
        } else {
          this.form.loanList = this.form.loanList.map(item => item.key === this.loanForm.key ? this.loanForm : item)
        }
        this.loanModalVisible = false
      } catch (error) {

      }
    },
    // 查询下拉选项
    async fetchOptions () {
      let { data } = await this.$axios.get(queryDictURL, {
        params: {
          typeValueList: [
            'ENTERPRISE_LOAN_TYPE',
            'ENTERPRISE_FINANCING_STAGE'
          ].join(',')
        }
      })
      if (data) {
        Object.keys(data).forEach(key => {
          this.optionsMap[key] = data[key].map(({ id, name }) => ({ text: name, value: id }))
          data[key].forEach(({ id, name }) => {
            this.optMap[key][id] = name
          })
        })
      }
    },
    numberValidatorGenerate (num) {
      return function (rule, value, callback) {
        console.log(String(value).length)
        if ((value || value === 0) && String(value).length > num) {
          callback(new Error(`数字最大长度限制为${num}位`))
        } else {
          callback()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
}
</style>
