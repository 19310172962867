<template>
  <div class="SurveyForm-container">
    <tabs-panel :tabs="tabs"
                :tabRouter="false"
                :activeLabel.sync="activeLabel"
                @change="switchTab"></tabs-panel>
  </div>
</template>

<script>
import { TabsPanel } from 'components/bussiness'
import baseForm from './BaseForm'
import customForm from './CustomForm'
import financialForm from './FinancialForm'

export default {
  name: 'SurveyForm',
  components: {
    TabsPanel
  },
  data () {
    return {
      tabs: [],
      commonProps: {
        surveyData: {
        }
      },
      activeLabel: '基础信息',
      disabled: true
    }
  },
  created () {
    if (this.$route.query.id) {
      this.disabled = false
    }
    this.tabs = [
      {
        label: '基础信息',
        component: baseForm,
        props: this.commonProps
      },
      {
        label: '客户记录',
        component: customForm,
        props: this.commonProps,
        disabled: this.disabled
      },
      // {
      //   label: '人事信息',
      //   props: this.commonProps,
      //   disabled: this.disabled
      // },
      {
        label: '财务信息',
        component: financialForm,
        props: this.commonProps,
        disabled: this.disabled
      }
      // {
      //   label: '技术能力',
      //   props: this.commonProps,
      //   disabled: this.disabled
      // },
      // {
      //   label: '荣誉资质',
      //   props: this.commonProps,
      //   disabled: this.disabled
      // }
    ]
  },
  methods: {
    // 切换tab时候 同步数据
    switchTab (index, tab) {

    },
    setActiveLabel ({ activeLabel, disabled }) {
      this.activeLabel = activeLabel
      this.tabs.forEach(item => {
        item.disabled = disabled
      })
    }
  }
}
</script>
