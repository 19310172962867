var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "EnterpriseRegisterForm" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.onSubmitSuccess,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          !_vm.isView
            ? _c(
                "col2-detail",
                [
                  _c(
                    "col2-block",
                    { attrs: { title: "企业简介" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "企业名称",
                            rules: [
                              {
                                required: true,
                                message: "请输入企业名称",
                                trigger: "blur",
                              },
                            ],
                            prop: "enterpriseName",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入名称",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.enterpriseName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "enterpriseName", $$v)
                              },
                              expression: "form.enterpriseName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "行业类别",
                            rules: [
                              {
                                required: true,
                                message: "请选择行业类别",
                                trigger: "change",
                              },
                            ],
                            prop: "industryType",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.optionsMap.ENTERPRISE_INDUSTRY_TYPE,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.industryType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "industryType", $$v)
                              },
                              expression: "form.industryType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "企业人数",
                            rules: [
                              {
                                required: true,
                                message: "请选择企业人数",
                                trigger: "change",
                              },
                            ],
                            prop: "enterpriseNum",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.optionsMap.ENTERPRISE_NUM,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.enterpriseNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "enterpriseNum", $$v)
                              },
                              expression: "form.enterpriseNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c("v-button", {
                            attrs: {
                              icon: _vm.showOptional
                                ? "el-icon-top"
                                : "el-icon-bottom",
                              text: "选填信息",
                            },
                            on: {
                              click: function ($event) {
                                _vm.showOptional = !_vm.showOptional
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.showOptional
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "战略性新兴产业" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      options:
                                        _vm.optionsMap.ENTERPRISE_NEW_INDUSTRY,
                                      width: _vm.width,
                                    },
                                    model: {
                                      value: _vm.form.enterpriseNewIndustry,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "enterpriseNewIndustry",
                                          $$v
                                        )
                                      },
                                      expression: "form.enterpriseNewIndustry",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "纳税人类型" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      options:
                                        _vm.optionsMap.ENTERPRISE_TAXPAYER_TYPE,
                                      width: _vm.width,
                                    },
                                    model: {
                                      value: _vm.form.taxpayerType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "taxpayerType", $$v)
                                      },
                                      expression: "form.taxpayerType",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "企业评级" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      options: _vm.optionsMap.ENTERPRISE_LEVEL,
                                      width: _vm.width,
                                    },
                                    model: {
                                      value: _vm.form.enterpriseLevel,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "enterpriseLevel",
                                          $$v
                                        )
                                      },
                                      expression: "form.enterpriseLevel",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "主要产品/服务" } },
                                [
                                  _c("v-textarea", {
                                    model: {
                                      value: _vm.form.mainProducts,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "mainProducts", $$v)
                                      },
                                      expression: "form.mainProducts",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.showOptional
                    ? _c(
                        "col2-block",
                        { attrs: { title: "工商信息" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "法人" } },
                            [
                              _c("v-input", {
                                attrs: { width: _vm.width, maxlength: 8 },
                                model: {
                                  value: _vm.form.legalPerson,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "legalPerson", $$v)
                                  },
                                  expression: "form.legalPerson",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "统一社会信用代码",
                                prop: "creditCode",
                                rules: [
                                  {
                                    validator: _vm.creditCodeValidate,
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-input", {
                                attrs: { width: _vm.width, maxlength: 18 },
                                model: {
                                  value: _vm.form.creditCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "creditCode", $$v)
                                  },
                                  expression: "form.creditCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "企业类型" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.optionsMap.ENTERPRISE_TYPE,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.enterpriseType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "enterpriseType", $$v)
                                  },
                                  expression: "form.enterpriseType",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "注册时间" } },
                            [
                              _c("v-datepicker", {
                                attrs: { width: _vm.width, type: "date" },
                                model: {
                                  value: _vm.form.registerTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "registerTime", $$v)
                                  },
                                  expression: "form.registerTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "注册资本",
                                prop: "registerCapital",
                                rules: [
                                  {
                                    validator: _vm.numberValidatorGenerate(9),
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-input-number", {
                                attrs: { width: _vm.width, max: 999999999 },
                                model: {
                                  value: _vm.form.registerCapital,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "registerCapital", $$v)
                                  },
                                  expression: "form.registerCapital",
                                },
                              }),
                              _vm._v("万元\n        "),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "企业规模" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.optionsMap.ENTERPRISE_SCALE,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.enterpriseScale,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "enterpriseScale", $$v)
                                  },
                                  expression: "form.enterpriseScale",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "参保人数",
                                prop: "insuredNum",
                                rules: [
                                  {
                                    validator: _vm.numberValidatorGenerate(10),
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-input-number", {
                                attrs: { width: _vm.width, max: 9999999999 },
                                model: {
                                  value: _vm.form.insuredNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "insuredNum", $$v)
                                  },
                                  expression: "form.insuredNum",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "企业地址" } },
                            [
                              _c("v-input", {
                                attrs: { width: _vm.width },
                                model: {
                                  value: _vm.form.enterpriseAddress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "enterpriseAddress", $$v)
                                  },
                                  expression: "form.enterpriseAddress",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "经营范围" } },
                            [
                              _c("v-textarea", {
                                attrs: { maxlength: 1000 },
                                model: {
                                  value: _vm.form.businessScope,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "businessScope", $$v)
                                  },
                                  expression: "form.businessScope",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "col2-block",
                    { attrs: { title: "跟踪信息" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "企业联系人",
                            rules: [
                              {
                                required: true,
                                message: "请输入企业联系人",
                                trigger: "blur",
                              },
                            ],
                            prop: "enterpriseContact",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入企业联系人",
                              maxlength: 8,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.enterpriseContact,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "enterpriseContact", $$v)
                              },
                              expression: "form.enterpriseContact",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系人职务" } },
                        [
                          _c("v-input", {
                            attrs: { width: _vm.width },
                            model: {
                              value: _vm.form.enterpriseContactDuty,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "enterpriseContactDuty", $$v)
                              },
                              expression: "form.enterpriseContactDuty",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "联系人电话",
                            rules: [
                              {
                                required: true,
                                message: "请输入联系人电话",
                                trigger: "blur",
                              },
                              { validator: _vm.validatePhone, trigger: "blur" },
                            ],
                            prop: "enterpriseContactPhone",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              maxlength: 20,
                              placeholder: "请输入联系人电话",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.enterpriseContactPhone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "enterpriseContactPhone",
                                  $$v
                                )
                              },
                              expression: "form.enterpriseContactPhone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "登记项目",
                            rules: [
                              {
                                required: true,
                                message: "请选择登记项目",
                                trigger: "blur",
                              },
                            ],
                            prop: "communityId",
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                attrs: {
                                  width: _vm.width,
                                  placeholder: "查询项目",
                                },
                                model: {
                                  value: _vm.form.communityId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "communityId", $$v)
                                  },
                                  expression: "form.communityId",
                                },
                              },
                              "v-select2",
                              _vm.communityParams,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "跟踪人员",
                            rules: [
                              {
                                required: true,
                                message: "请选择跟踪人员",
                                trigger: "blur",
                              },
                            ],
                            prop: "trackUserId",
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                attrs: {
                                  width: _vm.width,
                                  placeholder: "查询人员",
                                  subjoin: {
                                    communityId: _vm.form.communityId,
                                  },
                                },
                                model: {
                                  value: _vm.form.trackUserId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "trackUserId", $$v)
                                  },
                                  expression: "form.trackUserId",
                                },
                              },
                              "v-select2",
                              _vm.userParams,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                      !_vm.isEdit
                        ? [
                            _c(
                              "el-form-item",
                              { attrs: { label: "企业招商状态" } },
                              [_c("div", [_vm._v("意向企业")])]
                            ),
                          ]
                        : [
                            _c(
                              "el-form-item",
                              { attrs: { label: "企业招商状态" } },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.statusMap[_vm.form.status])
                                  ),
                                ]),
                              ]
                            ),
                            String(_vm.form.status) === "1"
                              ? [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "合同名称" } },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(_vm.form.contractName)),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "合同编号" } },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(_vm.form.contractId)),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "合同状态" } },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(_vm.form.contractStatus)),
                                      ]),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            String(_vm.form.status) === "2"
                              ? [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "流失原因" } },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.form.enterpriseLossReasonStr
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "备注" } },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(_vm.form.remark)),
                                      ]),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isView
            ? _c(
                "col2-detail",
                [
                  _c(
                    "col2-block",
                    { attrs: { title: "企业简介" } },
                    [
                      _c("col2-item", { attrs: { label: "企业名称" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.form.enterpriseName) +
                            "\n        "
                        ),
                      ]),
                      _c("col2-item", { attrs: { label: "行业类别" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.optMap.ENTERPRISE_INDUSTRY_TYPE[
                                _vm.form.industryType
                              ]
                            ) +
                            "\n        "
                        ),
                      ]),
                      _c("col2-item", { attrs: { label: "企业人数" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.optMap.ENTERPRISE_NUM[_vm.form.enterpriseNum]
                            ) +
                            "\n        "
                        ),
                      ]),
                      _c("col2-item", { attrs: { label: "战略性新兴产业" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.optMap.ENTERPRISE_NEW_INDUSTRY[
                                _vm.form.enterpriseNewIndustry
                              ]
                            ) +
                            "\n        "
                        ),
                      ]),
                      _c("col2-item", { attrs: { label: "纳税人类型" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.optMap.ENTERPRISE_TAXPAYER_TYPE[
                                _vm.form.taxpayerType
                              ]
                            ) +
                            "\n        "
                        ),
                      ]),
                      _c("col2-item", { attrs: { label: "企业评级" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.optMap.ENTERPRISE_LEVEL[
                                _vm.form.enterpriseLevel
                              ]
                            ) +
                            "\n        "
                        ),
                      ]),
                      _c("col2-item", { attrs: { label: "主要产品/服务" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.form.mainProducts) +
                            "\n        "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "col2-block",
                    { attrs: { title: "工商信息" } },
                    [
                      _c("col2-item", {
                        attrs: { label: "法人", text: _vm.form.legalPerson },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "统一社会信用代码",
                          text: _vm.form.creditCode,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "注册时间",
                          text: _vm.form.registerTime,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "注册资本",
                          text: _vm.form.registerCapital
                            ? `${_vm.form.registerCapital}万元`
                            : "未完善",
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "企业规模",
                          text: _vm.optMap.ENTERPRISE_SCALE[
                            _vm.form.enterpriseScale
                          ],
                        },
                      }),
                      _c("col2-item", {
                        attrs: { label: "参保人数", text: _vm.form.insuredNum },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "企业地址",
                          text: _vm.form.enterpriseAddress,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "经营范围",
                          text: _vm.form.businessScope,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "col2-block",
                    { attrs: { title: "跟踪信息" } },
                    [
                      _c("col2-item", {
                        attrs: {
                          label: "企业联系人",
                          text: _vm.form.enterpriseContact,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "联系人职务",
                          text: _vm.form.enterpriseContactDuty,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "联系人电话",
                          text: _vm.form.enterpriseContactPhone,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "登记项目",
                          text: _vm.form.communityName,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          label: "跟踪人员",
                          text: _vm.form.trackUserName,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("col2-item", {
                    attrs: {
                      label: "企业招商状态",
                      text: _vm.statusMap[_vm.form.status],
                    },
                  }),
                  String(_vm.form.status) === "1"
                    ? [
                        _c("col2-item", {
                          attrs: {
                            label: "合同名称",
                            text: _vm.form.contractName,
                          },
                        }),
                        _c("col2-item", {
                          attrs: {
                            label: "合同编号",
                            text: _vm.form.contractId,
                          },
                        }),
                        _c("col2-item", {
                          attrs: {
                            label: "合同状态",
                            text: _vm.form.contractStatus,
                          },
                        }),
                      ]
                    : _vm._e(),
                  String(_vm.form.status) === "2"
                    ? [
                        _c("col2-item", {
                          attrs: {
                            label: "流失原因",
                            text: _vm.form.enterpriseLossReasonStr,
                          },
                        }),
                        _c("col2-item", {
                          attrs: { label: "备注", text: _vm.form.remark },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }