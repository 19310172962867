// 获取列表
const getListURL = `${API_CONFIG.shopBaseURL}enterprise/listEnterpriseInfo`
// 导出列表
const exportListURL = `${API_CONFIG.shopBaseURL}orderSettle/exportOrderSettle`

// 新增
const createURL = `${API_CONFIG.shopBaseURL}enterprise/addEnterpriseInfo`
// 编辑
const editURL = `${API_CONFIG.shopBaseURL}enterprise/updateEnterpriseInfo`
// 查询详情
const queryURL = `${API_CONFIG.shopBaseURL}enterprise/getEnterpriseInfo`
// 查询跟踪人员
const queryCommunityUserURL = `${API_CONFIG.shopBaseURL}user/search/community/username`
// 流失
const lostURL = `${API_CONFIG.shopBaseURL}enterprise/loss`
// 绑定合同
const setContractURL = `${API_CONFIG.shopBaseURL}enterprise/setContractId`
// 招商分配
const setTrackUserURL = `${API_CONFIG.shopBaseURL}enterprise/setTrackUserId`
// 转为意向
const changeStateURL = `${API_CONFIG.shopBaseURL}enterprise/changeStatusIntention`
// 获取下拉选项的 key
// ENTERPRISE_FINANCING_STAGE	            企业融资阶段
// ENTERPRISE_INDUSTRY_TYPE	              企业行业类型
// ENTERPRISE_INTENTION_AREA	            企业意向面积
// ENTERPRISE_INVESTMENT_PROGRESS	        企业招商进度
// ENTERPRISE_LEVEL	                      企业评级
// ENTERPRISE_LOAN_TYPE	                  企业贷款类型
// ENTERPRISE_LOSS_REASON	                企业流失原因
// ENTERPRISE_NEW_INDUSTRY	              战略性新兴产业
// ENTERPRISE_NUM	                        企业人数
// ENTERPRISE_RISK_ASSESSMENT	            企业风险预估
// ENTERPRISE_SCALE	                      企业规模
// ENTERPRISE_TAXPAYER_TYPE	              纳税人类型
// ENTERPRISE_TYPE	                      企业类型
// ENTREPRENEURIAL_CHARACTERISTICE	      企业创业特征
const queryDictURL = `${API_CONFIG.shopBaseURL}enterprise/getDictList`
// 查询合同列表
const queryContractListURL = `${API_CONFIG.shopBaseURL}chargeOrganizationContract/getChargeContractSelectTo`
// 设置企业目标
const setTargetURL = `${API_CONFIG.shopBaseURL}enterprise/updateTargetSetting`
// 查询企业目标设置
const queryTargetURL = `${API_CONFIG.shopBaseURL}enterprise/getTargetSetting`
// 查询卡片信息接口
const queryTopInfoURL = `${API_CONFIG.shopBaseURL}enterprise/getCardInfo`
// 新增客户信息
const customCreateURL = `${API_CONFIG.shopBaseURL}enterpriseCustomer/addEnterpriseCustomer`
// 编辑客户信息
const customEditURL = `${API_CONFIG.shopBaseURL}enterpriseCustomer/updateEnterpriseCustomer`
// 查询客户信息
const costomQueryURL = `${API_CONFIG.shopBaseURL}enterpriseCustomer/getEnterpriseCustomer`
// 查询财务信息
const financeQueryURL = `${API_CONFIG.shopBaseURL}enterpriseFinance/getEnterpriseFinance`
// 操作财务信息
const financeUpdateURL = `${API_CONFIG.shopBaseURL}enterpriseFinance/updateEnterpriseFinance`
export {
  getListURL,
  exportListURL,
  createURL,
  editURL,
  queryURL,
  queryCommunityUserURL,
  lostURL,
  setContractURL,
  setTrackUserURL,
  queryDictURL,
  changeStateURL,
  queryContractListURL,
  queryTargetURL,
  setTargetURL,
  queryTopInfoURL,
  customCreateURL,
  customEditURL,
  costomQueryURL,
  financeQueryURL,
  financeUpdateURL
}
